<script setup lang="ts">
import { watch } from 'vue'

export interface LoadingModalObject {
  show: boolean
  index: number
  status: string
}

const loading = defineModel<LoadingModalObject>('loading', { required: true })

defineEmits<{ (e: 'cancel'): void }>()

const percentageMap: Record<number, number> = {
  0: 25,
  1: 50,
  2: 75,
  3: 100
}

watch(
  () => loading.value.show,
  (value) => {
    const dialog = document.getElementById('loading') as HTMLDialogElement

    if (value) {
      dialog?.showModal()
    } else {
      dialog?.close()
    }
  }
)
</script>

<template>
  <dialog id="loading" class="loading">
    <div class="__content">
      <svg width="156" height="114" viewBox="0 0 156 114" fill="none" xmlns="http://www.w3.org/2000/svg">
        <!-- 5 -->
        <path d="M0.5 0.5H47C47.2761 0.5 47.5 0.723858 47.5 1V17C47.5 17.2761 47.2761 17.5 47 17.5H0.5V0.5Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="2s" repeatCount="indefinite" />
        </path>
        <rect x="54.5" y="0.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="2s" repeatCount="indefinite" />
        </rect>
        <path d="M108.5 1C108.5 0.723858 108.724 0.5 109 0.5H155.5V17.5H109C108.724 17.5 108.5 17.2761 108.5 17V1Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="2s" repeatCount="indefinite" />
        </path>
        <!-- 4 -->
        <path d="M0.5 24.5H20C20.2761 24.5 20.5 24.7239 20.5 25V41C20.5 41.2761 20.2761 41.5 20 41.5H0.5V24.5Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1.5s" repeatCount="indefinite" />
        </path>
        <rect x="27.5" y="24.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1.5s" repeatCount="indefinite" />
        </rect>
        <rect x="81.5" y="24.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1.5s" repeatCount="indefinite" />
        </rect>
        <path d="M135.5 25C135.5 24.7239 135.724 24.5 136 24.5H155.5V41.5H136C135.724 41.5 135.5 41.2761 135.5 41V25Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1.5s" repeatCount="indefinite" />
        </path>
        <!-- 3 -->
        <path d="M0.5 48.5H47C47.2761 48.5 47.5 48.7239 47.5 49V65C47.5 65.2761 47.2761 65.5 47 65.5H0.5V48.5Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1s" repeatCount="indefinite" />
        </path>
        <rect x="54.5" y="48.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1s" repeatCount="indefinite" />
        </rect>
        <path d="M108.5 49C108.5 48.7239 108.724 48.5 109 48.5H155.5V65.5H109C108.724 65.5 108.5 65.2761 108.5 65V49Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="1s" repeatCount="indefinite" />
        </path>
        <!-- 2 -->
        <path d="M0.5 72.5H20C20.2761 72.5 20.5 72.7239 20.5 73V89C20.5 89.2761 20.2761 89.5 20 89.5H0.5V72.5Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="0.5s" repeatCount="indefinite" />
        </path>
        <rect x="27.5" y="72.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="0.5s" repeatCount="indefinite" />
        </rect>
        <rect x="81.5" y="72.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="0.5s" repeatCount="indefinite" />
        </rect>
        <path d="M135.5 73C135.5 72.7239 135.724 72.5 136 72.5H155.5V89.5H136C135.724 89.5 135.5 89.2761 135.5 89V73Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" begin="0.5s" repeatCount="indefinite" />
        </path>
        <!-- 1 -->
        <path d="M0.5 96.5H47C47.2761 96.5 47.5 96.7239 47.5 97V113C47.5 113.276 47.2761 113.5 47 113.5H0.5V96.5Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" repeatCount="indefinite" />
        </path>
        <rect x="54.5" y="96.5" width="47" height="17" rx="0.5" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" repeatCount="indefinite" />
        </rect>
        <path d="M108.5 97C108.5 96.7239 108.724 96.5 109 96.5H155.5V113.5H109C108.724 113.5 108.5 113.276 108.5 113V97Z" fill="#fff">
          <animate attributeName="fill" values="#fff;#C8A351;#fff" dur="5s" repeatCount="indefinite" />
        </path>
      </svg>
      <progress id="file" max="100" :value="percentageMap[loading.index]">{{ percentageMap[loading.index] }}%</progress>
      <div class="status">Downloading textures ({{ +loading.index + 1 }}/4)</div>
      <!-- <button class="cancel" @click="$emit('cancel')">Download annuleren</button> -->
    </div>
  </dialog>
</template>

<style scoped lang="scss">
.loading {
  background-color: #f3f3f3;
  border-radius: 16px;
  width: 376px;
  height: 380px;
  border: none;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      padding: 20px 0;
    }

    #file {
      margin: 10px 0;
      height: 6px;
      width: 280px;
    }

    .status {
      margin: 10px 0;
      font-size: 14px;
      line-height: 16.94px;
    }

    .cancel {
      margin: 25px 0px 10px 0px;
      width: 50%;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #d6d3d1;
      padding: 16px 24px 16px 24px;
    }
  }
}

progress[value] {
  --color: #57534e; /* the progress color */
  --background: #d6d3d1; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  margin: 0 10px;
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color);
}

@starting-style {
  dialog[open] {
    opacity: 0;
  }
}

dialog::backdrop {
  backdrop-filter: blur(5px);
  background-color: rgb(0 0 0 / 0%);
  transition: all 300ms allow-discrete;
}

dialog[open]::backdrop {
  background-color: rgb(0 0 0 / 25%);
}

@starting-style {
  dialog[open]::backdrop {
    background-color: rgb(0 0 0 / 0%);
  }
}
</style>
