<script setup lang="ts">
import { shallowRef, type Component } from 'vue'
import { dialog } from '@/components/modal/modal'

const [wrapper, showModal, closeModal] = dialog(() => import('@/container/Example.vue'));

interface Options {
  image: string;
  el: {
    component: Component;
    showModal: () => void;
    closeModal: () => void;
  }
}

const options = shallowRef<Options[]>([
  {
    image: 'client/src/assets/images/example.jpg',
    el: {
      component: wrapper,
      showModal,
      closeModal
    }
  }
]);
</script>

<template>
  <div class="choose">
    <ul>
      <template v-for="({ image, el }, index) in options" :key="index">
        <li>
          <a @click.prevent="el.showModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
              <path fill="#FFF"
                d="M8.059 0a8.059 8.059 0 0 1 6.362 13.007l3.286 3.286a1 1 0 0 1-1.414 1.414l-3.287-3.286A8.059 8.059 0 1 1 8.06 0Zm0 2a6.059 6.059 0 1 0 4.284 10.343l.013-.012A6.059 6.059 0 0 0 8.06 2Z" />
            </svg>
            <img :src="image" class="content__img" />
          </a>
        </li>
        <component :is="el.component" v-bind="{ ...$attrs }" @close="el.closeModal()" :image size="full" />
      </template>
    </ul>
  </div>
</template>
