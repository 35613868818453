import type { Canvas } from '.'
import type { Ref } from 'vue'

export function appendCanvasAnimated(element: Ref<HTMLCanvasElement | null>, data: Canvas) {
  const canvas = element.value
  if (!canvas) return

  const ctx = canvas.getContext('2d')

  canvas.width = 3840
  canvas.height = 2160

  const background = new Image()
  background.src = data.image

  background.onload = () => {
    const totalFrames = 10
    let currentFrame = 0

    const animate = () => {
      currentFrame++
      const progress = currentFrame / totalFrames

      ctx!.clearRect(0, 0, canvas.width, canvas.height)

      const pattern = ctx!.createPattern(background, 'repeat')

      if (pattern) {
        ctx!.globalAlpha = progress
        ctx!.fillStyle = pattern
        ctx!.fillRect(0, 0, canvas.width, canvas.height)
      }

      if (currentFrame < totalFrames) {
        requestAnimationFrame(animate)
      } else {
        ctx!.globalAlpha = 1
      }
    }

    requestAnimationFrame(animate)
  }
}
