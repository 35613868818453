<script setup lang="ts">
import Active from '@/container/components/filters/bricks/components/Active.vue'
import Choice from '@/container/components/filters/bricks/components/Choice.vue'

import type { Form } from '@/services/form'
import type { CanvasFilter, RulesSet } from '=/types'
import Popover from '@/components/Popover.vue';

const show = defineModel<boolean>('show', { required: true })

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()

const info = 'Deel van de natuursteen die wordt gebruikt om de kwaliteit van de stenen te verbeteren.'
</script>

<template>
  <div class="config__content visible">
    <h2 class="config__title">
      NG Lapide natuursteen
      <svg class="info" v-floating:hover="[Popover, { info }]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path v-floating:hover="[Popover, { info }]" fill="#A8A29E"
          d="M7.5 14.25a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z" />
        <path v-floating:hover="[Popover, { info }]" fill="#A8A29E"
          d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15Zm0 1.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z" />
        <path v-floating:hover="[Popover, { info }]" fill="#FFF"
          d="M8.482 9.375a.75.75 0 0 1 0 1.5H6.75a.75.75 0 1 1 0-1.5h1.732Z" />
        <path v-floating:hover="[Popover, { info }]" fill="#FFF"
          d="M7.62 6.188a.75.75 0 0 1 .75.75v3.187a.75.75 0 1 1-1.5 0l-.001-2.438h-.112a.75.75 0 0 1-.743-.648l-.007-.101a.75.75 0 0 1 .75-.75h.862Z" />
        <path v-floating:hover="[Popover, { info }]" fill="#FFF"
          d="M7.388 3.748c.241 0 .456.114.593.292l.02.032.032.02a.75.75 0 0 1 .285.492l.007.102a.937.937 0 1 1-.937-.938Z" />
        <path v-floating:hover="[Popover, { info }]" fill="#FFF"
          d="M7.388 3.748c.517 0 .937.42.937.938a.75.75 0 0 1-1.344.458l-.02-.032-.032-.02a.75.75 0 0 1-.285-.492l-.006-.102a.75.75 0 0 1 .75-.75Z" />
      </svg>

      <svg class="icon-close-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.167 12.167"
        @click.prevent="show = false">
        <path fill="#323232"
          d="m1.28.22 4.803 4.802L10.886.22a.75.75 0 0 1 1.061 1.06L7.144 6.083l4.803 4.803a.75.75 0 0 1-1.06 1.061L6.082 7.144 1.28 11.947a.75.75 0 0 1-1.06-1.06l4.802-4.804L.22 1.28A.75.75 0 0 1 1.28.22Z" />
      </svg>
    </h2>
    <p class="config__text">Maak uw keuze voor één of meerdere Lapide sorteringen en maak uw unieke mix</p>
    <ul class="config__grid active">
      <Active :values />
      <template v-if="values.form.value.bricks.length < 4">
        <li><label @click="show = !show" class="item showmore"></label></li>
      </template>
    </ul>
    <hr />
  </div>
  <ul class="config__grid stones">
    <Choice :values :rules />
  </ul>
</template>
