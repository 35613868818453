import { ref } from 'vue'

interface ToastConfig {
  id: number
  message: string
  type: 'success' | 'error' | 'info' | 'loading'
  duration?: number
}

const toasts = ref<ToastConfig[]>([])

let toastIdCounter = 0

const showToast = ({ message, type, duration }: Omit<ToastConfig, 'id'>) => {
  const id = ++toastIdCounter

  toasts.value.push({ id, message, type, duration })

  if (duration !== undefined) {
    setTimeout(() => {
      removeToast(id)
    }, duration ?? 3000)
  }

  return id
}

const removeToast = (id: number) => {
  toasts.value = toasts.value.filter((toast) => toast.id !== id)
}

export const useToast = () => {
  return {
    toasts,
    showToast,
    removeToast
  }
}
