<script setup lang='ts'>
import { ref } from 'vue';

const props = defineProps<{ name?: string }>()

const images = import.meta.glob<Record<string, string>>(`~/images/previews/*`, { eager: true })

const image = ref()

Object.keys(images).forEach((key) => key.includes(props.name!) && (image.value = images[key]))
</script>

<template>
    <img :src="image?.default" class="item__img" />
</template>
