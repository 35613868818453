import { useFloating } from '@/composables/floating'
import { markRaw } from 'vue'

import type { App, DirectiveBinding } from 'vue'

declare global {
  interface EventTarget {
    contains: (value: HTMLElement) => boolean
  }
}

interface MapEvent {
  [key: string]: () => void
}

const DIRECTIVE_NAME = 'floating'

export function createFloating() {
  return {
    install(app: App) {
      app.directive(DIRECTIVE_NAME, {
        mounted(el: HTMLElement, binding: DirectiveBinding) {
          const { tooltip, show, hide, createInstance } = useFloating()

          createInstance(el, binding)

          function click() {
            el.addEventListener('click', show)
            document.addEventListener('click', (event) => {
              if (event.target?.contains(el)) return
              // @ts-expect-error
              if (tooltip.value?.contains(event.target)) return
              hide()
            })
          }

          function hover() {
            el.addEventListener('mouseenter', show)
            // @ts-expect-error
            document.addEventListener('mouseover', (event) => !tooltip.value?.contains(event.target) && hide())
          }

          const showEventMap = markRaw<MapEvent>({ click, hover })
          binding.arg ? showEventMap[binding.arg]() : hover()
        }
      })
    }
  }
}
